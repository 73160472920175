import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const DMEA24Page = () => {
  return (
    <Layout pageTitle="DMEA24" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>News - Februar 2024</p> 
                <h1>Konzept «Patientenportal interoperabel und maximal integriert» the i-engineers und dem Universitätsmedizin Rostock ist ein Gewinner des Wettbewerbs «Digitalisierungsthema der Gesundheitswirtschaft 2024»</h1>
               
                <p className="text-medium">
                Wir freuen uns sehr, dass unser Projekt «Patientenportal interoperabel und maximal integriert» in Kooperation mit dem Universitätsmedizin Rostock am diesjährigen eHealth Inkubator Event der Entscheiderfabrik in Düsseldorf zu den Gewinnern des Wettbewerbs «Digitalisierungsthema der Gesundheitswirtschaft 2024» gewählt wurde.</p>
                </div>

                <div className={col12}>
                <h3>Um was geht es im Projekt?</h3>
                <p >Problemstellung:</p>
                <p>Um die Herausforderungen in der Gesundheitsversorgung zu meistern, braucht es effiziente Patientenbetreuung und optimale Ressourcennutzung. Der Mangel an integrierten Systemen verursacht ineffiziente administrative Prozesse und erschwert die Interaktion zwischen medizinischem Personal und Patienten.</p>
                <p >Ziel:</p>
                <p>Das Projektziel ist die Konzepterstellung für integrierte Portal-Systeme (Zuweiser-, Patienten- und Verlegungsportal), fokussiert auf medizinische Interoperabilität. Das Konzept dient als Blaupause zur Prozesskostenminimierung, Erlösoptimierung und effizienter Fördermittelnutzung, wobei es primär um die Ausarbeitung relevanter Aspekte für die Einführung der Systeme geht.</p>
                <p>Die Universitätsmedizin Rostock und the i-engineers entwickeln ein Verständnis für technische und prozessuale Anforderungen, inklusive Integration verschiedener Primärdokumentationssysteme und Kompatibilität mit IHE-/ISiK-konformen Anbindungen, sowie XDS-Umgebungen und FHIR-Strukturen.</p>
                  </div>

                  <div className={col12}>
                <h3>Besten Dank an alle, die für uns abgestimmt haben.</h3>
                <p>Extradank geht an unseren Klinikpartner in der Projektvorstellung, die Universitätsmedizin Rostock, und die beiden Kliniken, die sich auf unser Projekt als weitere Klinikpartner gewählt haben. Das sind die Vestische Caritas-Kliniken und das Krankenhaus Porz Am Rhein.</p>
                <p>Wir freuen uns sehr, auf die gemeinsame Umsetzung des Projekts in den kommenden 9 Monaten.</p>
                <p>Großer Dank auch an die Entscheiderfabrik für die wieder großartige Organisation und Durchführung des Events in Düsseldorf am 14. Und 15. Februar 2024.</p>
                <div className={buttonGroup}><a href="https://www.krankenhaus-it.de/item.3095/entscheider-event-die-digitalisierungsthemen-der-gesundheitswirtschaft-2024-sind-gewaehlt.html" className="button-outline blue" target="_blank" rel="noreferrer"> Zur Pressemitteilung </a> </div>
                <div style={{ marginBottom: 40 }} className={buttonGroup}><a href="https://www.tie.ch/portale/" className="button-outline blue" target="_blank" rel="noreferrer"> Zur ti-e Portalwelt </a> </div>
                
                  </div>

                  <div className={col12}>

                  <p>Anbei gehts zum animierten Video, der integrierten Portalunterstützung von Zuweiser bis Nachsorge. Wir haben uns dabei erlaubt, den realen Inhalt etwas überzeichnet darzustellen. Viel Spaß damit.</p>
                <div className={buttonGroup}><a 
                    href="https://www.tie.ch/landingpages/portal-journey/"
                    className="button-outline blue" 
                    target="_blank" 
                    rel="noreferrer"> Zum Video </a> </div>
                  </div>


                  <div className={col12}>
                <p style={{  marginTop:30,}}>Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an.</p>
                <div className={buttonGroup}><a 
                    href="https://www.tie.ch/landingpages/newsletter-anmeldung"
                    className="button-outline blue" 
                    target="_blank" 
                    rel="noreferrer"> Zur Newsletter Anmeldung </a> </div>
                  </div>

             
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DMEA24Page;
